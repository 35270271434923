import { TFunction } from 'react-i18next';
import { $enum } from 'ts-enum-util';
import * as Yup from 'yup';

import { IntBool } from '../../../../enums/boolean';
import { CountryPhone } from '../../../../enums/countries';
import { ClientSubsidiaryCollection } from '../../../../types/api/clients';
import {
  AddEditOrderRequestPayload,
  CalculatorRequestPayload,
  CodPaymentStatus,
  LetterType,
  OrderPackaging,
  OrderResource,
  OrderType,
  PriorityTimeFrame,
  ReplacementShipment,
  ReturnDocument,
  ReturnDocumentFilter,
  ShippingLocation,
  TimeFrame,
  UnregisteredClientId,
} from '../../../../types/api/orders';
import { LabelValue } from '../../../../types/options';
import { ReduxState } from '../../../../types/redux';
import {
  paymentToPayer,
  serviceCashAttributeEn,
  servicePayerAttributeEn,
} from '../../../../utils/api/orders';
import { isStreetFreeInputAllowed } from '../../../../utils/constants/misc';
import { objDiff } from '../../../../utils/helpers/object';
import { tryEnumValue } from '../../../../utils/helpers/parse';
import {
  formatMobileNumber,
  formatPhoneNumber,
  getPhoneorMobileNumberRegion,
  isValidMobileNumber,
  isValidPhoneNumber,
} from '../../../../utils/helpers/phoneNumbers';
import {
  conditionallyRequiredField,
  yupRequiredField,
} from '../../../../utils/helpers/yup';

export type Action = 'create' | 'edit' | 'recreate';

export type SpecialProduct = {
  name?: string;
  id?: number;
};

export enum CustomerRole {
  Sender = 'sender',
  Recipient = 'recipient',
  Orderer = 'orderer',
}

export enum FallbackValues {
  Weight = '1.00',
  Volume = '0.00',
  Quantity = '1',
  Redemption = '0.00',
}

export enum RedemptionReceiver {
  Sender = 'sender',
  Orderer = 'orderer',
}

export enum RedemptionFieldValidation {
  Valid,
  UnregisteredSender,
  NonEmptyInsuredAmount,
}

export enum RedemptionReceiverFieldValidation {
  Valid,
  UnregisteredSender,
  EmptyRedemption,
  SameSenderAndOrderer,
  OrdererRedemption,
}

export type FormFields = Required<AddEditOrderRequestPayload> & {
  // General
  customer_role: CustomerRole;
  internal_shipment: boolean;

  // Sender
  ulica_od_ime?: string;
  adresa_od?: string;
  sender_contact_id: string;
  mobilen_od_region: CountryPhone;
  telefon_od_region: CountryPhone;

  // Recipient
  ulica_do_ime?: string;
  adresa_do?: string;
  recipient_contact_id: string;
  mobilen_do_region: CountryPhone;
  telefon_do_region: CountryPhone;

  // Order settings
  related_order_filter: string;
  // TODO - move these to ApiData after API starts supporting them
  sirina: string;
  visina: string;
  dolzina: string;
};

type FormFieldsForCalculatorData = Pick<
  FormFields,
  | 'proizvod_id'
  | 'klient_od_id'
  | 'klient_do_id'
  | 'ulica_od_ime'
  | 'adresa_od'
  | 'ulica_do_ime'
  | 'adresa_do'
  | 'mesto_od_ime'
  | 'mesto_od_id'
  | 'mesto_do_ime'
  | 'mesto_do_id'
  | 'broj_od'
  | 'broj_do'
  | 'povraten_dokument'
  | 'replacement_shipment'
  | 'tezina'
  | 'otkup'
  | 'vrednost'
  | 'hub_od_id'
  | 'hub_do_id'
  | 'pickup_location_type_id'
  | 'delivery_location_type_id'
  | 'volumen'
  | 'payments'
  | 'kolicina'
  | 'delivery_term_id'
  | 'priority'
  | 'personal_delivery'
>;

// Explanation:
//  - internal_shipment - 1 and 2 are fixed values for sender and recipient, respectively.
//    Further, we can't get data for random users due to privacy limitations of GET /clients/:id,
//    so the only time that an order is truly internal is when both the client and recipient
//    are subsidiaries of the current user and/or the client itself.
export function getDefaultValues(
  clientId: ReduxState['user']['client_id'],
  order: OrderResource | undefined,
  subsidiaries: ClientSubsidiaryCollection | undefined
): FormFields {
  const isRedemptionFieldValid: boolean =
    !!order &&
    validateRedemptionField(order.klient_od_id, order.vrednost) ===
      RedemptionFieldValidation.Valid;

  const isRedemptionReceiverFieldValid: boolean =
    !!order &&
    validateRedemptionReceiverField(
      order.klient_od_id,
      order.klient_do_id,
      order.otkup,
      clientId
    ) === RedemptionReceiverFieldValidation.Valid;

  return {
    // General info
    customer_role: order
      ? clientId === order?.klient_od_id
        ? CustomerRole.Sender
        : clientId === order?.klient_do_id
        ? CustomerRole.Recipient
        : CustomerRole.Orderer
      : CustomerRole.Sender,
    internal_shipment: order
      ? order?.klient_od_id !== UnregisteredClientId.Sender &&
        order?.klient_do_id !== UnregisteredClientId.Recipient &&
        !!subsidiaries?.find((s) => s.id) &&
        !!subsidiaries?.find((s) => s.id === order?.klient_do_id)
      : false,

    // Sender
    klient_od_id: order?.klient_od_id ?? UnregisteredClientId.Sender,
    klient_od_ime: order?.klient_od_ime ?? '',
    sender_contact_id: '',
    mobilen_od: order?.mobilen_od ?? '',
    mobilen_od_region: getPhoneorMobileNumberRegion(order?.mobilen_od ?? ''),
    telefon_od: order?.telefon_od ?? '',
    telefon_od_region: getPhoneorMobileNumberRegion(order?.telefon_od ?? ''),
    mesto_od_id: order?.mesto_od_id ?? '',
    ulica_od_id: order?.ulica_od_id ?? '',
    adresa_od: order?.adresa_od ?? '',
    ulica_od_ime: order?.street_from_name ?? '',
    broj_od: order?.broj_od ?? '',
    vlez_od: order?.vlez_od ?? '',
    stan_od: order?.stan_od ?? '',
    pickup_location_type_id:
      order?.pickup_location_type_id ?? ShippingLocation.HomeAddress,
    hub_od_id: order?.hub_od_id ?? null,
    mesto_od_ime: order?.mesto_od_ime ?? '',

    // Recipient
    klient_do_id: order?.klient_do_id ?? UnregisteredClientId.Recipient,
    klient_do_ime: order?.klient_do_ime ?? '',
    recipient_contact_id: '',
    mobilen_do: order?.mobilen_do ?? '',
    mobilen_do_region: getPhoneorMobileNumberRegion(order?.mobilen_do ?? ''),
    telefon_do: order?.telefon_do ?? '',
    telefon_do_region: getPhoneorMobileNumberRegion(order?.telefon_do ?? ''),
    mesto_do_id: order?.mesto_do_id ?? '',
    ulica_do_id: order?.ulica_do_id ?? '',
    ulica_do_ime: order?.street_to_name ?? '',
    adresa_do: order?.adresa_do ?? '',
    broj_do: order?.broj_do ?? '',
    vlez_do: order?.vlez_do ?? '',
    stan_do: order?.stan_do ?? '',
    delivery_location_type_id:
      order?.delivery_location_type_id ?? ShippingLocation.HomeAddress,
    hub_do_id: order?.hub_do_id ?? null,
    mesto_do_ime: order?.mesto_do_ime ?? '',

    // Order settings
    proizvod_id: (order?.proizvod_id as OrderType) ?? OrderType.Package,
    tezina: order?.tezina ?? FallbackValues.Weight,
    volumen: order?.volumen ?? FallbackValues.Volume,
    kolicina: order?.kolicina ?? FallbackValues.Quantity,
    sirina: order?.sirina ?? '',
    visina: order?.visina ?? '',
    dolzina: order?.dolzina ?? '',
    otkup: isRedemptionFieldValid
      ? order?.otkup ?? FallbackValues.Redemption
      : FallbackValues.Redemption,
    vrednost: order?.vrednost ?? '',
    reference1: order?.reference1 ?? '',
    reference2: order?.reference2 ?? '',
    reference_id: order?.reference_id ?? '',
    povraten_dokument: Boolean(order?.povraten_dokument) ?? IntBool.False,
    return_document_type_id: order?.return_document_type_id ?? '',
    fragile: order?.fragile ?? IntBool.False,
    two_man_delivery: order?.two_man_delivery ?? IntBool.False,
    adresnica_service_packaging_id: tryEnumValue(
      order?.adresnica_service_packaging_id,
      OrderPackaging
    ),
    komentar: order?.komentar ?? '',
    related_order_filter: '',
    klient_otkup_id: order
      ? isRedemptionReceiverFieldValid
        ? order.klient_otkup_id === order.klient_od_id
          ? RedemptionReceiver.Sender
          : RedemptionReceiver.Orderer
        : null
      : RedemptionReceiver.Sender,
    specijaliziran_proizvod_id: order?.specijaliziran_proizvod_id ?? '',
    personal_delivery: order?.personal_delivery ?? 0,
    replacement_shipment: Boolean(order?.replacement_shipment) ?? IntBool.False,
    delivery_term_id: order?.delivery_term_id ?? TimeFrame.NextWorkDay,
    priority: Boolean(order?.priority) ?? IntBool.False,

    // Payments
    payments: order?.payments ?? [],
    ordererInvoice: order?.ordererInvoice ?? 0,
    senderInvoice: order?.senderInvoice ?? 0,
    recipientInvoice: order?.recipientInvoice ?? 0,

    // Misc (pass-through)
    klient_naracatel_id: clientId,
    calc_price: '0',
  };
}

export function toApiData(
  values: FormFields,
  clientId: ReduxState['user']['client_id'],
  shouldIncludePayments: boolean = true
): AddEditOrderRequestPayload {
  const returnValue = objDiff<FormFields, AddEditOrderRequestPayload>(values, {
    customer_role: true,
    internal_shipment: true,
    ulica_od_ime: true,
    ulica_do_ime: true,
    sirina: true,
    visina: true,
    dolzina: true,
    related_order_filter: true,
    sender_contact_id: true,
    recipient_contact_id: true,
    mobilen_do_region: true,
    telefon_do_region: true,
    mobilen_od_region: true,
    telefon_od_region: true,
  });

  // Check of product_id is letter or package --> 1-package 2-letter
  // ts disabled becs only for checking
  // @ts-ignore: Unreachable code error
  if (parseInt(returnValue.proizvod_id) === OrderType.Package) {
    delete returnValue.personal_delivery;
  }

  // Set value of return_document number from boolean because of InputSwitch
  if (returnValue.povraten_dokument === 'false') {
    delete returnValue.return_document_type_id;
  }

  returnValue.povraten_dokument =
    returnValue.povraten_dokument === 'true'
      ? ReturnDocument.Include
      : ReturnDocument.None;

  returnValue.replacement_shipment =
    returnValue.replacement_shipment === 'true'
      ? ReplacementShipment.Include
      : ReplacementShipment.None;

  returnValue.priority =
    returnValue.priority === 'true'
      ? PriorityTimeFrame.Priority
      : PriorityTimeFrame.None;

  const isRedemptionFilledOut =
    returnValue.otkup && parseFloat(returnValue.otkup) > 0;

  // Delivery and pickup hubs should not be sent if their associated checkboxes are unchecked
  if (returnValue.pickup_location_type_id === ShippingLocation.HomeAddress) {
    delete returnValue.hub_od_id;
  }

  if (returnValue.delivery_location_type_id === ShippingLocation.HomeAddress) {
    delete returnValue.hub_do_id;
  }

  if (returnValue.delivery_term_id === TimeFrame.SameWorkDay) {
    delete returnValue.priority;
  }

  // if senderId is 1(unregistered) and recipientId is same as clientId then otkup is 0
  if (
    values.klient_do_id === clientId &&
    values.klient_od_id === UnregisteredClientId.Recipient
  ) {
    delete returnValue.otkup;
  }

  if (isRedemptionFilledOut) {
    if (
      // here typescript is disabled because we use here only for checking
      returnValue.klient_otkup_id === RedemptionReceiver.Orderer ||
      // @ts-ignore: Unreachable code error
      (parseInt(returnValue.klient_od_id) === 1 &&
        // @ts-ignore: Unreachable code error
        parseInt(returnValue.klient_do_id) === 2)
    ) {
      returnValue.klient_otkup_id = String(clientId);
    } else {
      returnValue.klient_otkup_id = String(returnValue.klient_od_id);
    }
  } else {
    returnValue.klient_otkup_id = null;
  }

  // If payments are not edited, don't send them (objDiff doesn't filter them, as they are truly different)
  if (!shouldIncludePayments) {
    delete returnValue.payments;
  } else {
    returnValue.payments?.forEach((payment) => {
      delete payment.id;
      delete payment.created;
    });
  }

  // Mobile and phone numbers
  if (returnValue.telefon_od) {
    returnValue.telefon_od = formatPhoneNumber(
      returnValue.telefon_od,
      values.telefon_od_region
    );
  }

  if (returnValue.telefon_do) {
    returnValue.telefon_do = formatPhoneNumber(
      returnValue.telefon_do,
      values.telefon_do_region
    );
  }

  if (returnValue.mobilen_od) {
    returnValue.mobilen_od = formatMobileNumber(
      returnValue.mobilen_od,
      values.mobilen_od_region
    );
  }

  if (returnValue.mobilen_do) {
    returnValue.mobilen_do = formatMobileNumber(
      returnValue.mobilen_do,
      values.mobilen_do_region
    );
  }

  delete returnValue.senderInvoice;
  delete returnValue.recipientInvoice;
  delete returnValue.ordererInvoice;

  delete returnValue.mesto_do_ime;
  delete returnValue.mesto_od_ime;

  return returnValue;
}

export function getValidationSchema(
  t: TFunction,
  isMobileOrPhoneRequired: boolean,
  isStreetNoRequired: boolean
) {
  console.log('isStreetNoRequired ', isStreetNoRequired);
  return Yup.object().shape({
    // General
    customer_role: Yup.string().required(yupRequiredField(t, t('My role'))),
    internal_shipment: Yup.boolean(),

    // Sender
    klient_od_id: Yup.string().required(yupRequiredField(t, t('Sender Name'))),
    klient_od_ime: Yup.string().required(yupRequiredField(t, t('Sender Name'))),
    mobilen_od: Yup.string()
      .test(
        'mobilen_od',
        t('Invalid mobile phone number'),
        (value, context) => {
          if (!value) {
            return true;
          }

          const { mobilen_od_region } = context.parent;
          return isValidMobileNumber(value, mobilen_od_region);
        }
      )
      .test(
        'mobilen_od',
        t('At least phone or mobile must be filled out'),
        function (value) {
          if (!isMobileOrPhoneRequired) {
            return true;
          }

          const { telefon } = this.parent;

          if (!telefon) {
            return !!value;
          }

          return true;
        }
      ),
    telefon_od: Yup.string()
      .test('telefon_od', t('Invalid phone number'), (value, context) => {
        if (!value) {
          return true;
        }

        const { telefon_od_region } = context.parent;
        return isValidPhoneNumber(value, telefon_od_region);
      })
      .test(
        'telefon_od',
        t('At least phone or mobile must be filled out'),
        function (value) {
          if (!isMobileOrPhoneRequired) {
            return true;
          }

          const { mobilen } = this.parent;

          if (!mobilen) {
            return !!value;
          }

          return true;
        }
      ),
    mesto_od_id: Yup.string().required(yupRequiredField(t, t('Place'))),
    mesto_od_ime: Yup.string(),
    ulica_od_id: conditionallyRequiredField(
      Yup.string().nullable(),
      !isStreetFreeInputAllowed,
      yupRequiredField(t, t('Street'))
    ),
    adresa_od: conditionallyRequiredField(
      Yup.string(),
      isStreetFreeInputAllowed,
      yupRequiredField(t, t('Address'))
    ),
    ulica_od_ime: Yup.string(),
    broj_od: conditionallyRequiredField(
      Yup.string(),
      isStreetNoRequired,
      yupRequiredField(t, t('Street No.'))
    ),
    vlez_od: Yup.string(),
    stan_od: Yup.string(),
    hub_od_id: Yup.string().nullable(),
    pickup_location_type_id: Yup.number(),

    // Recipient
    klient_do_id: Yup.string().required(yupRequiredField(t, t('Sender Name'))),
    klient_do_ime: Yup.string().required(yupRequiredField(t, t('Sender Name'))),
    mobilen_do: Yup.string()
      .test(
        'mobilen_do',
        t('Invalid mobile phone number'),
        (value, context) => {
          if (!value) {
            return true;
          }

          const { mobilen_do_region } = context.parent;
          return isValidMobileNumber(value, mobilen_do_region);
        }
      )
      .test(
        'mobilen_do',
        t('At least phone or mobile must be filled out'),
        function (value) {
          if (!isMobileOrPhoneRequired) {
            return true;
          }

          const { telefon } = this.parent;

          if (!telefon) {
            return !!value;
          }

          return true;
        }
      ),
    telefon_do: Yup.string()
      .test('telefon_do', t('Invalid phone number'), (value, context) => {
        if (!value) {
          return true;
        }

        const { telefon_do_region } = context.parent;
        return isValidPhoneNumber(value, telefon_do_region);
      })
      .test(
        'telefon_do',
        t('At least phone or mobile must be filled out'),
        function (value) {
          if (!isMobileOrPhoneRequired) {
            return true;
          }

          const { mobilen } = this.parent;

          if (!mobilen) {
            return !!value;
          }

          return true;
        }
      ),
    mesto_do_id: Yup.string().required(yupRequiredField(t, t('Place'))),
    mesto_do_ime: Yup.string(),
    ulica_do_id: conditionallyRequiredField(
      Yup.string().nullable(),
      !isStreetFreeInputAllowed,
      yupRequiredField(t, t('Street'))
    ),
    adresa_do: conditionallyRequiredField(
      Yup.string(),
      isStreetFreeInputAllowed,
      yupRequiredField(t, t('Address'))
    ),
    ulica_do_ime: Yup.string(),
    broj_do: conditionallyRequiredField(
      Yup.string(),
      isStreetNoRequired,
      yupRequiredField(t, t('Street No.'))
    ),
    vlez_do: Yup.string(),
    stan_do: Yup.string(),
    hub_do_id: Yup.string().nullable(),
    delivery_location_type_id: Yup.number(),

    // Order settings
    proizvod_id: Yup.string().required(yupRequiredField(t, t('Product type'))),
    tezina: Yup.string().test({
      test: function (tezina) {
        const { proizvod_id } = this.parent;

        if (proizvod_id !== OrderType.Package) {
          return true;
        }

        return !isNaN(Number(tezina));
      },
      name: 'tezina',
      message: yupRequiredField(t, t('Weight')),
    }),
    volumen: Yup.string(),
    vrednost: Yup.mixed().test(
      'vrednost',
      t('Only one of redemption or insured amount can be present at a time.'),
      (value, { parent }) => {
        const otkup = parent.otkup;

        return !(
          otkup &&
          parseFloat(otkup) > 0 &&
          value &&
          parseFloat(value) > 0
        );
      }
    ),
    otkup: Yup.mixed().test(
      'otkup',
      t('Only one of redemption or insured amount can be present at a time.'),
      (value, { parent }) => {
        const vrednost = parent.vrednost;

        return !(
          vrednost &&
          parseFloat(vrednost) > 0 &&
          value &&
          parseFloat(value) > 0
        );
      }
    ),
    reference1: Yup.string(),
    reference2: Yup.string(),
    reference_id: Yup.string(),
    replacement_shipment: Yup.string(),
    povraten_dokument: Yup.string(),
    komentar: Yup.string(),
    fragile: Yup.boolean(),
    two_man_delivery: Yup.boolean(),
    adresnica_service_packaging_id: Yup.mixed<OrderPackaging>().oneOf(
      $enum(OrderPackaging).getValues()
    ),

    // Payments
    payments: Yup.array()
      .required(yupRequiredField(t, t('Payments')))
      .min(1, yupRequiredField(t, t('Payments'))),
  });
}

export function getActionFromPathname(pathname: string): Action {
  // Pathname can either be "/orders/create" ,
  //  "/orders/d+/edit" or "/orders/d+/recreate"
  const splitted = pathname.split('/');

  return splitted[splitted.length - 1] as Action;
}

export function getOrderPackagingOptions(
  t: TFunction
): LabelValue<OrderPackaging>[] {
  return [
    {
      label: t('CardboardBox_300x200x200'),
      value: OrderPackaging.CardboardBox_300x200x200,
    },
    {
      label: t('CardboardBox_500x300x300'),
      value: OrderPackaging.CardboardBox_500x300x300,
    },
    {
      label: t('CardboardBox_500x300x500'),
      value: OrderPackaging.CardboardBox_500x300x500,
    },
    {
      label: t('SmallBag'),
      value: OrderPackaging.SmallBag,
    },
    {
      label: t('BigBag'),
      value: OrderPackaging.BigBag,
    },
  ];
}

export function getLetterTypeOptions(t: TFunction): LabelValue<LetterType>[] {
  return [
    {
      label: t('Regular'),
      value: LetterType.Regular,
    },
    {
      label: t('Personal Delivery'),
      value: LetterType.PersonalDelivery,
    },
    {
      label: t('Recommended'),
      value: LetterType.Recommended,
    },
  ];
}

export function getPaymentStatusOptions(
  t: TFunction
): LabelValue<CodPaymentStatus>[] {
  return [
    {
      label: t('Paid'),
      value: CodPaymentStatus.Paid,
    },
    {
      label: t('Unpaid'),
      value: CodPaymentStatus.Unpaid,
    },
  ];
}

export function getTimeFrameOptions(t: TFunction): LabelValue<TimeFrame>[] {
  return [
    {
      label: t('Same work day'),
      value: TimeFrame.SameWorkDay,
    },
    {
      label: t('Next work day'),
      value: TimeFrame.NextWorkDay,
    },
    {
      label: t('Three work days'),
      value: TimeFrame.ThreeWorkDays,
    },
  ];
}

export function getCalculatorData(
  values: FormFieldsForCalculatorData,
  loggedInUserClientId: ReduxState['user']['client_id']
): CalculatorRequestPayload {
  let returnObj: CalculatorRequestPayload = {
    // Required
    product_id: values.proizvod_id,
    pickup_city: values.mesto_od_id ? values.mesto_od_ime : '',
    delivery_city: values.mesto_do_id ? values.mesto_do_ime : '',
    // Optional
    sender_id: String(values.klient_od_id),
    recipient_id: String(values.klient_do_id),
    orderer_id: String(loggedInUserClientId),
    pickup_hub_id:
      values.pickup_location_type_id !== ShippingLocation.HomeAddress &&
      values.hub_od_id !== null
        ? values.hub_od_id
        : undefined,
    delivery_hub_id:
      values.delivery_location_type_id !== ShippingLocation.HomeAddress &&
      values.hub_do_id !== null
        ? values.hub_do_id
        : undefined,
    return_document: values.povraten_dokument ? '1' : '0',
    replacement_shipment: values.replacement_shipment ? '1' : '0',
    weight: values.tezina,
    volume: values.volumen,
    quantity: values.kolicina,
    delivery_term_id: values.delivery_term_id,
    priority: values.priority ? '1' : '0',
    personal_delivery: values.personal_delivery,
  };

  if (values.mesto_od_id) {
    returnObj.pickup_address = isStreetFreeInputAllowed
      ? values.adresa_od
      : `${values.ulica_od_ime} ${values.broj_od}, ${values.mesto_od_ime}`;
  }

  if (values.mesto_do_id) {
    returnObj.delivery_address = isStreetFreeInputAllowed
      ? values.adresa_do
      : `${values.ulica_do_ime} ${values.broj_do}, ${values.mesto_do_ime}`;
  }

  if (values.otkup && parseInt(values.otkup) > 0) {
    returnObj.cod_amount = values.otkup;
  } else {
    if (values.vrednost && parseInt(values.vrednost) > 0) {
      returnObj.value_amount = values.vrednost;
    }
  }

  for (const payment of values.payments) {
    returnObj[servicePayerAttributeEn(payment.usluga_id!)] = paymentToPayer(
      payment.klient_id!,
      values.klient_od_id,
      values.klient_do_id
    );

    returnObj[serviceCashAttributeEn(payment.usluga_id!)] = payment.gotovina;
  }

  return returnObj;
}

export function validateRedemptionField(
  klient_od_id: FormFields['klient_od_id'],
  vrednost: FormFields['vrednost']
): RedemptionFieldValidation {
  if (klient_od_id === UnregisteredClientId.Sender) {
    return RedemptionFieldValidation.UnregisteredSender;
  }

  if (!!vrednost && parseFloat(vrednost) > 0) {
    return RedemptionFieldValidation.NonEmptyInsuredAmount;
  }

  return RedemptionFieldValidation.Valid;
}

export function getRedemptionTooltip(
  t: TFunction,
  validation: RedemptionFieldValidation
): string {
  switch (validation) {
    case RedemptionFieldValidation.UnregisteredSender:
      return t(
        'Cod amount cannot be paid if the sender is unregistered and you are the recipient.'
      );

    case RedemptionFieldValidation.NonEmptyInsuredAmount:
      return t(
        'Only one of redemption or insured amount can be present at a time.'
      );

    case RedemptionFieldValidation.Valid:
      return '';
  }
}

export function validateRedemptionReceiverField(
  klient_od_id: FormFields['klient_od_id'],
  klient_do_id: FormFields['klient_do_id'],
  otkup: FormFields['otkup'],
  clientId: ReduxState['user']['client_id']
): RedemptionReceiverFieldValidation {
  if (klient_od_id === clientId) {
    return RedemptionReceiverFieldValidation.SameSenderAndOrderer;
  } // if ordererId and senderId are the same then disable Cod receive dropdown and put sender as value, and free codAmount input

  if (
    klient_do_id === clientId &&
    klient_od_id === UnregisteredClientId.Sender
  ) {
    return RedemptionReceiverFieldValidation.UnregisteredSender;
  } // if ordererId and receiverId are the same and senderId is 1(unregistered) then disable dropdown and input for codAmount and receiver cod

  if (
    klient_od_id === UnregisteredClientId.Sender &&
    klient_do_id === UnregisteredClientId.Recipient
  ) {
    return RedemptionReceiverFieldValidation.OrdererRedemption;
  } // if senderId is 1(unregistered) and recipientId is 2(unregistered) then disable dropdown and put ordered as value, and free codAmount input

  if (!otkup || parseFloat(otkup) === 0) {
    return RedemptionReceiverFieldValidation.EmptyRedemption;
  } // if cod amount is enabled input and value is 0 then disable dropdown otherwise enable

  return RedemptionReceiverFieldValidation.Valid;
}

export function getRedemptionReceiverTooltip(
  t: TFunction,
  validation: RedemptionReceiverFieldValidation
): string {
  switch (validation) {
    case RedemptionReceiverFieldValidation.UnregisteredSender:
      return t(
        'Cod amount cannot be paid if the sender is unregistered and you are the recipient.'
      );

    case RedemptionReceiverFieldValidation.EmptyRedemption:
      return t('Redemption is empty.');

    case RedemptionReceiverFieldValidation.SameSenderAndOrderer:
      return t('You are both the sender and the orderer.');

    case RedemptionReceiverFieldValidation.OrdererRedemption:
      return t('Cod will be paid to the orderer');

    case RedemptionReceiverFieldValidation.Valid:
      return '';
  }
}

export function getOrderReturnDocumentOptions(
  t: TFunction
): LabelValue<ReturnDocumentFilter>[] {
  return [
    {
      label: t('No return document'),
      value: ReturnDocumentFilter.None,
    },
    {
      label: t('Announcement for a returned document'),
      value: ReturnDocumentFilter.Include,
    },
    {
      label: t('Return document'),
      value: ReturnDocumentFilter.ReturnDocument,
    },
  ];
}
